import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Wallet } from 'app/model/admin.model';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
username = "stageauth"
password = "ubZGH8XtUYYZQ6Ka73S4vV"

  setResource(url: string): void {
    this.API_SERVER = this.API_SERVER + url;
  }

  private API_SERVER = `${environment.BASE_API}/`;


  private header = new HttpHeaders({
    'content-type': 'application/json;charset=UTF-8',
    'Authorization': 'Bearer ' +  localStorage.getItem("id_token"),
    'Access-Control-Allow-Origin': '*',
    }
  );

  constructor(private httpClient: HttpClient) { }
  

  // ADMINS
  getClaimedTokens(): Observable<any> {
    return this.httpClient.get<any>(this.API_SERVER + 'admins' + '/getClaimedTokens/0/100', {headers: this.header})
  }

  sendLockingFromStake() {
    return this.httpClient.get<any>(this.API_SERVER + 'admins' + '/sendLockingFromStake', {headers: this.header})
  }
  sendTokenSale() {
    return this.httpClient.get<any>(this.API_SERVER + 'admins' + '/sendWithdrawTokensale', {headers: this.header})
  }
  getWalletPreview() {
    return this.httpClient.get<any>(this.API_SERVER + 'admins' + '/walletPreview', {headers: this.header})
  }
  withdrawBalance() {
    return this.httpClient.get<any>(this.API_SERVER + 'admins' + '/getTotalAdaToken' + '/withdraw-mainnet-test', {headers: this.header})
  }
  depositBalance() {
    return this.httpClient.get<any>(this.API_SERVER + 'admins' + '/getTotalAdaToken' + '/deposit-testnet', {headers: this.header})
  }
  coldBalance() {
    return this.httpClient.get<any>(this.API_SERVER + 'admins' + '/getTotalAdaToken' + '/cold-storage', {headers: this.header})
  }
  calculate() {
    return this.httpClient.get<any>(this.API_SERVER + 'admins' + '/calculate', {headers: this.header})
  }
  calucateStaked() {
    return this.httpClient.get<any>(this.API_SERVER + 'admins' + '/calculateStaking', {headers: this.header})
  }
  getLogs() {
    return this.httpClient.get<any>(this.API_SERVER + 'admins' + '/lastLogs' , {headers: this.header})
  }
  calucateTokenSale() {
    return this.httpClient.get<any>(this.API_SERVER + 'admins' + '/calculateTokenSale', {headers: this.header})
  }
  signIn(email: string, password: string) {
    return this.httpClient.post<any>(this.API_SERVER + 'admins' + '/signIn', { email, password })
  }
  checkWithdrawAssetTokenSale() {
   return this.httpClient.get<any>(this.API_SERVER + 'admins' + '/check.withdraw.asset.treasury' + '/single-asset-tokensale', {headers: this.header})
  }
  countTokenSale() {
    return this.httpClient.get<any>(this.API_SERVER + 'admins' + '/countTokenSale', {headers: this.header})
  }
  countLocking() {
    return this.httpClient.get<any>(this.API_SERVER + 'admins' + '/countLocking', {headers: this.header})
  }
  countLockingFromStake() {
    return this.httpClient.get<any>(this.API_SERVER + 'admins' + '/countLockingFromStake', {headers: this.header})
  }
  unproccesedTransactions() {
    return this.httpClient.delete<any>(this.API_SERVER + 'admins' + '/unprocessedTransactions', {headers: this.header})
  }
  checkBalance(walletAddress: string) {
    return this.httpClient.post<any>(this.API_SERVER + 'admins' + '/checkBalanceMultiToken', {walletAddress}, {headers: this.header})
  }
  checkSingleAssetStaked() {
    return this.httpClient.get<any>(this.API_SERVER + 'admins' + '/check.withdraw.asset.treasury' + '/single-asset-staking', {headers: this.header})
   }
   checkSingleMultiAsset() {
    return this.httpClient.get<any>(this.API_SERVER + 'admins' + '/check.withdraw.asset.treasury' + '/multi-asset-staking', {headers: this.header})
   }
   getAllLogs() { 
     return this.httpClient.get<any>(this.API_SERVER + 'admins' + '/allLogs', {headers: this.header})
   }
   mnemonics(){ 
     return this.httpClient.get<any>(this.API_SERVER + 'admins' + '/mnemonics', {headers: this.header})
   }


  // CLAIMS 
  getClaims() {
    return this.httpClient.get<any>(this.API_SERVER + 'claims' + '/claim.from.revuto')
  }
  getClaimsStaked() {
    return this.httpClient.get<any>(this.API_SERVER + 'claims' + '/claimed.locking.from.stake.with.sum')
  }
  getTokenSale() {
    return this.httpClient.get<any>(this.API_SERVER + 'claims' + '/claimed.tokensale.with.sum')
  }
  getClaimsAllWithSum() {
    return this.httpClient.get<any>(this.API_SERVER + 'claims' + '/claimed.all.with.sum') 
  }
  


  // WALLETS
  getDepositWallets() {
    return this.httpClient.get<any>(this.API_SERVER + 'wallets' + '/addresses.total.deposit')
  }
  getWithdrawDetails() {
    return this.httpClient.get<any>(this.API_SERVER + 'wallets' + '/withdraw.details')
  }
  getDepositDetails() {
    return this.httpClient.get<any>(this.API_SERVER + 'wallets' + '/deposit.details')
  }
  getStorageDetails() {
    return this.httpClient.get<any>(this.API_SERVER + 'wallets' + '/coldStorage.details')
  }
  postWallet(customerId: number, custody: string, reference: string, createdBy:string, name: string, isActive: boolean, isMain: boolean, classType: string, mnemonics:string ) {
    return this.httpClient.post<any>(this.API_SERVER + 'admins' + '/wallet', {customerId, custody, reference, createdBy, name, isActive, isMain,  classType, mnemonics})
  }


  // CUSTOMERS
  getCustomers() {
    return this.httpClient.get<any>(this.API_SERVER + 'customers')
  }

  // TRANSACTIONS
  getTransactions() {
    return this.httpClient.get<any>(this.API_SERVER + 'transactions/all');
  }

  // TOKENS 
  getTokens() {
    return this.httpClient.get<any>(this.API_SERVER + 'tokens')
  }
  postTokens(tokenName: string, tokenPolicy: string, numberOfDecimals: number, tokenImage: string, encodedName: string, tokenLink: string) {
    return this.httpClient.post<any>(this.API_SERVER + 'tokens', {tokenName, tokenPolicy, numberOfDecimals, tokenImage, encodedName, tokenLink})
  }
}   
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject  } from 'rxjs';
import { NbToastrService } from '@nebular/theme';
import { ApiService } from 'app/service/api.service';
import { Admin } from 'app/model/admin.model';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    isLoggedIn = false;
    user: Observable<Admin>
    private unsub$: Subject<void> = new Subject<void>();
    // Useful to know about auth state even between reloads.
    // Replace emailConfirmationSent and emailToConfirm.
    constructor(
        public aS: ApiService, // Inject Firebase auth service
        public router: Router,
        public ngZone: NgZone, // NgZone service to remove outside scope warning
        public nbToastr: NbToastrService,
    ) {

    }

    // Sign in with email/password
    SignIn(email, password) {

        
        return this.aS
            .signIn(email, password).toPromise()
            .then(async (result) => {
                // check if email verifiedq
                if (result.token) {
                    this.setSession(result.token);
                    this.nbToastr.success("Welcome to REVUTO administrator panel. It is great to have you here.", 'Authentication success.');
                    setTimeout(() => {
                        this.ngZone.run(() => {
                            this.router.navigate(['pages/dashboard']);
                           
                        });
                    }, 2000)
                } else {
                    
                    // destroy credentials if there is no user mail verified.
                    // this.afAuth.signOut();
                    this.nbToastr.warning("Please verify your email. It arrived in your mailbox", 'Authentication failed.');
                    this.ngZone.run(() => {
                        this.router.navigate(['auth/login']);
                    });
                }
            })
            .catch((error) => {
                this.nbToastr.danger(error.message, 'Authentication failed.');
            });
            
    }

    private setSession(token, iat: number = null) {
        localStorage.setItem('id_token', token);
        //localStorage.setItem("expires_at", JSON.stringify(authResult.expirationTime.valueOf()) );
    }
}

<div class="header-container">
  <div class="logo-container">
    <img src="../../../../assets/images/revuto.png" class="w-30 ml-5">
  
  </div>
 
</div>

<div class="header-container">
  <button nbButton class="float-right" size="medium" (click)="logOut()" status="danger">LOGOUT</button>
  <nb-actions size="small">


      <nb-user [nbContextMenu]="userMenu"
               [onlyPicture]="userPictureOnly"
               [name]="user?.name"
               [picture]="user?.picture">
      </nb-user>
  </nb-actions>
</div>
